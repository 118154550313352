import "../App.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const CurrentIPD = () => {

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState([10]);
  const [filteredData, setFilteredData] = useState([]);


  const getIpdPatientList = async () => {

    await fetch("https://hospitalapi.projectupdate.in/api/getIPDDetails")
      .then(response => response.json())
      .then(dt => {
        console.log(dt);

        setData(dt);
        setTotalPage(Math.ceil(dt.length / pageSize))
      });


  }
  useEffect(() => {
    getIpdPatientList();
  }, []);



  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;

    setFilteredData(data.slice(start, end));
  }, [data]);

  const nextPage = () => {
    if (current < totalPage) {
      setCurrent(current + 1);
    }
  }
  const prevPage = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  }

  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;

    setFilteredData(data.slice(start, end));
  }, [current]);


  return (
    <div class="wrapper">
      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">
          <Breadcrumb title="Currently Admitted IPD Patient" />

          <div class="content">
            <div class="container-fluid">
              <div class="row">

                <div class="col-lg-12">
                  <div class="card card-primary card-outline">
                    <div class="card-body">
                      <form
                        action="#"
                        method="post"
                        accept-charset="utf-8"
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Type your UHID here"
                                name="uhid"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Type your Patient Name here"
                                name="patient_name"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Type your IPD No here"
                                name="ipd"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group col-md-12 row">
                              <div class="col-md-6 mx-0">
                                <button type="submit" class="btn btn-primary ">
                                  Search
                                </button>
                              </div>
                              <div class="col-md-6 mx-0 px-0">
                                <button type="submit" class="btn btn-primary ">
                                  Show All
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <br />
                      <table class="table table-striped table-bordered rounded">
                        <thead>
                          <tr role="row">
                            <th > Sr.No. </th>
                            <th>UHID</th>
                            <th>Patient Name</th>
                            <th>Phone </th>
                            <th> Address</th>
                            <th>Admission Type</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th class="col-md-2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((record, index) =>
                            <tr key={record.admission_id}>
                              <td>{index + 1}</td>
                              <td>{record.uhid}</td>
                              <td>{record.pname}</td>
                              <td>{record.phone}</td>
                              <td>{record.paddress}</td>
                              <td>{record.gender}</td>
                              <td>{record.age}</td>
                              <td></td>

                              <td>
                                <Button href="/edit-ipd-bill"
                                  variant="outline-primary"
                                  size="sm"
                                >Edit Bill</Button>
                                {" "}
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                >View Bill</Button>
                              </td>
                            </tr>
                          )
                          }


                        </tbody>
                      </table>

                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-5">
                        <div
                          class="dataTables_info"

                        >

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                        <div
                          class="dataTables_paginate paging_simple_numbers"
                          id="example_paginate"
                        >
                          <ul class="pagination">
                            <li
                              class="paginate_button page-item previous disabled"
                              id="example_previous"
                            >
                              <Button
                                class="page-link"
                              onClick={prevPage}
                              >
                                Prev
                              </Button>
                            </li>
                            <li class="paginate_button page-item active">
                              <span>
                                Page {current} to {totalPage}
                              </span>
                            </li>
                            <li
                              class="paginate_button page-item next disabled"
                              id="example_next"
                            >
                              <Button class="page-link"
                              onClick={nextPage}
                              >
                                Next
                              </Button>
                            </li>
                          </ul>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>




        </div>
      </div>

      <Footer />
    </div>
  );
};
export default CurrentIPD;
