

import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';

const Report = () => {
  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Date Wise Report" />

          <div class="content">
            <div class="container-fluid">
              <div class="row">


                <div class="col-lg-12">

                  <div class="card card-primary card-outline">

                    <div class="card-body">
                      <form action="https://hospitalapp.projectupdate.in/index.php/reception/dailyCollectionReport" method="get" accept-charset="utf-8">

                        <div class="row float-end">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Select report date</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input type="date" class="form-control" placeholder="Type your UHID here" name="date" value="2023-12-05" />
                            </div>
                          </div>


                          <div class="col-md-4">
                            <div class="form-group">

                              <button type="submit" class="btn btn-default">
                                Search
                              </button>



                            </div>
                          </div>
                        </div>
                      </form>
                      <br />
                      <table class="table table-striped table-bordered rounded" id="example11">

                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>OPDID </th>
                            <th>Billing Date</th>
                            <th>Amount</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="2"></th>
                            <th>Total</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                      <div class="pagination float-end">
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>




        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Report;