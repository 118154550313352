
import { Link } from "react-router-dom";
const Sidebar = () => {
    return (
        <>
            <div class="sidebar-wrapper" data-simplebar="true">
                <div class="sidebar-header">

                    <div>
                        <h4 class="logo-text fs-6">Hospital </h4>
                    </div>
                    <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
                    </div>
                </div>

                <ul class="metismenu" id="menu">
                    <li>
                        <ul>
                            <li>
                                <Link to="/"> <i class="bx bx-heading"></i>Hospital Dashboard</Link>
                            </li>
                        </ul>
                    </li>
                    <li class="menu-label">Menu</li>
                    <li>
                        <a class="dropdown-menu" href="javascript:;">
                            <div class="parent-icon"><i class='bx bx-user'></i>
                            </div>
                            <div class="menu-title">Master</div>
                        </a>
                        <ul>
                            <li>
                                <Link to="/uhid-master"> <i class="bx bx-right-arrow-alt"></i>UHID Master</Link>
                            </li>
                            <li>
                                <Link to="/generate-uhid"> <i class="bx bx-right-arrow-alt"></i>Generate UHID</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="dropdown-menu" href="javascript:;">
                            <div class="parent-icon"><i class="bx bx-bed"></i>
                            </div>
                            <div class="menu-title">OPD</div>
                        </a>
                        <ul>
                            <li>
                                <Link to="/book-opd"> <i class="bx bx-right-arrow-alt"></i>Book OPD</Link>
                            </li>
                            <li>
                                <Link to="/current-opd"> <i class="bx bx-right-arrow-alt"></i>Current OPD Patient</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="dropdown-menu" href="javascript:;">
                            <div class="parent-icon"><i class="bx bx-bed"></i>
                            </div>
                            <div class="menu-title">IPD</div>
                        </a>
                        <ul>
                            <li>
                                <Link to="/current-ipd"> <i class="bx bx-right-arrow-alt"></i> IPD Patient List</Link>
                            </li>
                            <li>
                                <Link to="/readmit-ipd"> <i class="bx bx-right-arrow-alt"></i>ReAdmit IPD Patient</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="dropdown-menu" href="javascript:;">
                            <div class="parent-icon"><i class="bx bxs-report"></i>
                            </div>
                            <div class="menu-title">Report</div>
                        </a>
                        <ul class="has-arrow">
                            <li>
                                <Link to="/report"> <i class="bx bx-right-arrow-alt"></i>Day Wise Report</Link>
                            </li>
                        </ul>
                    </li>

                </ul>


            </div>

            <header>
                <div class="topbar d-flex align-items-center">
                    <nav class="navbar navbar-expand">
                        <div class="mobile-toggle-menu"><i class='bx bx-menu'></i>
                        </div>



                    </nav>
                </div>
            </header>
        </>

    );
}

export default Sidebar;