const Widget = ({ title, value, icon, color }) => {
  return (
    <div class={`card radius-10 border-${color} border-start border-0 border-4`}>
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div>
            <p class="mb-0">{title}</p>
            <h4 class={`my-1 text-${color}`}>{value}</h4>
          </div>
          <div class={`text-${color} ms-auto font-35`}>
            {icon}
          </div>
        </div>
      </div>
    </div >
  );
}
export default Widget;