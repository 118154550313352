import "../App.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
const UhidMaster = () => {

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const getUhidPatientList = async () => {
    await fetch("https://hospitalapi.projectupdate.in/api/getUHIDDetails")
      .then(response => response.json())
      .then(dt => {
        // console.log(dt);
        setData(dt);
        setTotalPage(Math.ceil(dt.length / pageSize));
      });

  }
  useEffect(() => {
    getUhidPatientList();
    console.log(filteredData);

  }, []);

  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;

    setFilteredData(data.slice(start, end));
  }, [data]);

  const nextPage = () => {
    if (current < totalPage) {
      setCurrent(current + 1);
    }
  }
  const prevPage = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  }

  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;

    setFilteredData(data.slice(start, end));
  }, [current]);







  return (
    <div class="wrapper">
      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">
          <Breadcrumb title="UHID Master" />


          <div class="card">
            <div class="card-body">
              {/*search bar*/}
              <div class="row">
                <div class="col-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type your UHID here"

                  />
                </div>
                <div class="col-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type your Patient Name"
                    aria-label="State"
                  />
                </div>
                <div class="col-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Tpye your Phone No. here"
                    aria-label="Zip"
                  />
                </div>
                <div class="col-1">
                  <button type="button" class="btn btn-primary ">
                    Search
                  </button>
                </div>
                <div class="col-2">
                  <button type="button" class="btn btn-primary ">
                    Show All
                  </button>
                </div>
              </div>
              <br />
              {/*search bar end*/}
              <div class="table-responsive">
                <div
                  id="exam1ple_wrapper"
                  class="dataTables_wrapper dt-bootstrap5 no-footer"
                >

                  <div class="row">
                    <div class="col-sm-12">
                      <table
                        id="example"
                        class="table table-striped table-bordered"
                      >
                        <thead class="col-12 col-lg-12">
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th>UHID No.</th>
                            <th>Patient Name</th>
                            <th>Phone No.</th>
                            <th class="col-3">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((record, index) =>
                            <tr key={record.patient_id} >
                              <td>{index + 1}</td>
                              <td>{record.uhid}</td>
                              <td>{record.patient_name}</td>
                              <td>{record.phone_number}</td>
                              <td >

                                <Button href="/edit-opd-patient-detail" variant="outline-primary" size="sm">
                                  Edit
                                </Button>
                                {' '}
                                <Button href="/view-ipd-patient-detail" variant="outline-primary" size="sm">
                                  View IPD Info
                                </Button>
                                {' '}
                                <Button href="/view-opd-patient-detail" variant="outline-primary" size="sm">
                                  View OPD Info
                                </Button>
                              </td>
                            </tr>
                          )
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-5">
                      <div
                        class="dataTables_info"

                      >

                      </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                      <div
                        class="dataTables_paginate paging_simple_numbers"
                        id="example_paginate"
                      >
                        <ul class="pagination">
                          <li
                            class="paginate_button page-item previous disabled"
                            id="example_previous"
                          >
                            <Button
                              class="page-link"
                              onClick={prevPage}
                            >
                              Prev
                            </Button>
                          </li>
                          <li class="paginate_button page-item active">
                            <span>
                              Page {current} to {totalPage}
                            </span>
                          </li>
                          <li
                            class="paginate_button page-item next disabled"
                            id="example_next"
                          >
                            <Button class="page-link"
                              onClick={nextPage}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default UhidMaster;
