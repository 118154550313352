

import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';

const EditOpdBill = () => {
  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Edit OPD Bill" />

          <div class="row">
            {/* Card1 */}
            <div class="col-3">

              <div class="col-12 col-lg-12">
                <div class="card border-primary border-bottom border-3 border-0">
                  <div class="card-body">

                    {/* Form */}

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleInputEmail1">UHID</label>
                        <input type="text" class="form-control" id="" value="M-000002" readonly="" />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Booking date</label>
                        <input type="text" class="form-control" id="" value="2023-09-15" readonly="" />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Patient Name</label>
                        <input type="text" class="form-control" id="" value="KUNDAN" readonly="" />
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Address</label>
                        <input type="text" class="form-control" id="" value="BHAGWANPUR POST SARERI KANNAUJ" readonly="" />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Gender</label>
                        <input type="text" class="form-control" id="" value="male" readonly="" />
                      </div>


                      <div class="form-group">
                        <label for="exampleInputEmail1">Phone</label>
                        <input type="text" class="form-control" id="" value="9918071565" readonly="" />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Age</label>
                        <input type="text" class="form-control" id="" value="15" readonly="" />
                      </div>







                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-9">
              {/* Card2 */}

              <div class="col-12 col-lg-12">
                <div class="card border-primary border-bottom border-3 border-0">
                  <div class="card-body">
                    <div class="row g-3">
                      <div class="col-4 ">
                        <p class="fw-bold mt-1">Enter Patient UHID</p>
                      </div>
                      <div class="col-5">
                        <input type="text" class="form-control" placeholder="Enter Patient UHID" aria-label="Zip" />
                      </div>
                      <div class="col-3">
                        <button type="button" class="btn btn-primary ">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card3 */}

              <div class="card border-primary border-bottom border-3 border-0">
                <div class="card-body">


                  <div class="row">


                    <div class="col-md-1">
                      <div class="form-group">

                        <label for="exampleInputEmail1">Sr.No.</label>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">

                        <label for="exampleInputEmail1">Charge Name</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <label for="exampleInputEmail1">Rate</label>
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="form-group">

                        <label for="exampleInputEmail1">Quantity</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <label for="exampleInputEmail1">Remark</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1">
                      <div class="form-group">

                        <label for="exampleInputEmail1">1</label>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">

                        <select class="form-control" name="head[]">

                          <option value="">Select Head</option>
                          <option value="1">RBS CHARGE</option>
                          <option value="2">REGISTRATION</option>
                          <option value="3">BED CHARGE (G.W)</option>
                          <option value="4">P.V.T ROOM</option>
                          <option value="5">S.PVT CHAREGE</option>
                          <option value="6">NURSING CHAREGE</option>
                          <option value="7">NURSING    ICU  CHAREGE</option>
                          <option value="8">B.M.W CHAREGE</option>
                          <option value="9">OT CHAREGE</option>
                          <option value="10">SURGEON FESS</option>
                          <option value="11">ANESTHETIC CHAREGE</option>
                          <option value="12">FUEL CHAREGE</option>
                          <option value="13">G.D.M.O CHAREGE</option>
                          <option value="14">INFUTION CHAREGE</option>
                          <option value="15">VENTILETOR CHAREGE</option>
                          <option value="16">INCUVATION CHAREGE</option>
                          <option value="17">ICU AIR BED CHAREGE</option>
                          <option value="18">ICU BED CHAREGE</option>
                          <option value="19">NICU BED CHAREGE</option>
                          <option value="20">DR MK SINGH</option>
                          <option value="21">DR M.K SHALUJA</option>
                          <option value="22">DR SUBHAM JAIN</option>
                          <option value="23">DR RAJEEV KAINTH</option>
                          <option value="24">DR SHAILESH SINGH</option>
                          <option value="25">DR ANURAG SINGH</option>
                          <option value="26">DR GAURAV ARYA</option>
                          <option value="27">BLOOD TRANSFUTION CHAREGE</option>
                          <option value="28">NEBULIZATION CHAREGE</option>
                          <option value="29">PACKEGE MEDICINE</option>
                          <option value="30">C.ARM CHAREGE</option>
                          <option value="31">ASSISTANT CHAREGE</option>
                          <option value="32">IMPLANT CHAREGE</option>
                          <option value="33">MONNITER CHAREGE</option>
                          <option value="34">RT CHAREGE</option>
                          <option value="35">FOLLICY CHAREGE</option>
                          <option value="36">OXYGEN CHAREGE/  HRS</option>
                          <option value="37">DRESSING CHAREGE</option>
                          <option value="38">INFUTION PUMP</option>
                          <option value="39">STICHINGE CHAREGE</option>
                          <option value="40">CONSULTATION(DR R.K CHAUBEY)</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter rate" name="rate[]" />
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter quantity" name="quantity[]" value="1" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter remark" name="remark[]" />
                      </div>
                    </div>


                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-1">
                      <div class="form-group">

                        <label for="exampleInputEmail1">2</label>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">

                        <select class="form-control" name="head[]">

                          <option value="">Select Head</option>
                          <option value="1">RBS CHARGE</option>
                          <option value="2">REGISTRATION</option>
                          <option value="3">BED CHARGE (G.W)</option>
                          <option value="4">P.V.T ROOM</option>
                          <option value="5">S.PVT CHAREGE</option>
                          <option value="6">NURSING CHAREGE</option>
                          <option value="7">NURSING    ICU  CHAREGE</option>
                          <option value="8">B.M.W CHAREGE</option>
                          <option value="9">OT CHAREGE</option>
                          <option value="10">SURGEON FESS</option>
                          <option value="11">ANESTHETIC CHAREGE</option>
                          <option value="12">FUEL CHAREGE</option>
                          <option value="13">G.D.M.O CHAREGE</option>
                          <option value="14">INFUTION CHAREGE</option>
                          <option value="15">VENTILETOR CHAREGE</option>
                          <option value="16">INCUVATION CHAREGE</option>
                          <option value="17">ICU AIR BED CHAREGE</option>
                          <option value="18">ICU BED CHAREGE</option>
                          <option value="19">NICU BED CHAREGE</option>
                          <option value="20">DR MK SINGH</option>
                          <option value="21">DR M.K SHALUJA</option>
                          <option value="22">DR SUBHAM JAIN</option>
                          <option value="23">DR RAJEEV KAINTH</option>
                          <option value="24">DR SHAILESH SINGH</option>
                          <option value="25">DR ANURAG SINGH</option>
                          <option value="26">DR GAURAV ARYA</option>
                          <option value="27">BLOOD TRANSFUTION CHAREGE</option>
                          <option value="28">NEBULIZATION CHAREGE</option>
                          <option value="29">PACKEGE MEDICINE</option>
                          <option value="30">C.ARM CHAREGE</option>
                          <option value="31">ASSISTANT CHAREGE</option>
                          <option value="32">IMPLANT CHAREGE</option>
                          <option value="33">MONNITER CHAREGE</option>
                          <option value="34">RT CHAREGE</option>
                          <option value="35">FOLLICY CHAREGE</option>
                          <option value="36">OXYGEN CHAREGE/  HRS</option>
                          <option value="37">DRESSING CHAREGE</option>
                          <option value="38">INFUTION PUMP</option>
                          <option value="39">STICHINGE CHAREGE</option>
                          <option value="40">CONSULTATION(DR R.K CHAUBEY)</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter rate" name="rate[]" />
                      </div>
                    </div>


                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter quantity" name="quantity[]" value="1" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">

                        <input type="text" class="form-control" id="" placeholder="Enter remark" name="remark[]" />
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12">



                      <button type="submit" class="btn btn-primary">Update Bill</button>

                    </div>

                  </div>





                </div>
              </div>

            </div>
          </div>

        </div>

        <Footer />
      </div>
    </div>
  );
}
export default EditOpdBill;