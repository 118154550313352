import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import BookOpd from "./pages/book-opd";
import UhidMaster from "./pages/UhidMaster";
import GenerateUhid from "./pages/GenerateUhid";
import CurrentOPD from "./pages/CurrentOPD";
import CurrentIPD from "./pages/CurrentIPD";
import ReAdmitIPD from "./pages/ReAdmitIPD";
import Report from "./pages/Report";
import View_IPD_Patient_Detail from "./pages/View_IPD_Patient_Detail";
import View_OPD_Patient_Detail from "./pages/View_OPD_Patient_Detail";
import EditOpdPatientDetail from "./pages/EditOpdPatientDetail";
import EditOpdBill from "./pages/EditOpdBill";
import EditIpdBill from "./pages/EditIpdBill";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/book-opd" element={<BookOpd />} />
        <Route path="/uhid-master" element={<UhidMaster />} />
        <Route path="/generate-uhid" element={<GenerateUhid />} />
        <Route path="/current-opd" element={<CurrentOPD />} />
        <Route path="/current-ipd" element={<CurrentIPD />} />
        <Route path="/readmit-ipd" element={<ReAdmitIPD />} />
        <Route path="/report" element={<Report />} />
        <Route path="/view-ipd-patient-detail" element={<View_IPD_Patient_Detail />} />
        <Route path="/view-opd-patient-detail" element={<View_OPD_Patient_Detail />} />
        <Route path="/edit-opd-patient-detail" element={<EditOpdPatientDetail />} />
        <Route path="/edit-opd-bill" element={<EditOpdBill />} />
        <Route path="/edit-ipd-bill" element={<EditIpdBill />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
