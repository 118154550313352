import "../App.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";


const CurrentOPD = () => {

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filteredData, setFilteredData] = useState([]);

  const getOpdPatientList = async () => {

    await fetch("https://hospitalapi.projectupdate.in/api/getOPDDetails")
      .then(response => response.json())
      .then(dt => {

        setData(dt);
        setTotalPage(Math.ceil(dt.length / pageSize));
      });

  }
  useEffect(() => {
    getOpdPatientList();

  }, []);
  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;
    console.log(start + '-' + end);
    setFilteredData(data.slice(start, end));
  }, [data]);

  const nextPage = () => {
    if (current < totalPage) {
      setCurrent(current + 1);
    }
  }
  const prevPage = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }


  }
  useEffect(() => {
    let start = (current - 1) * pageSize;
    let end = start + pageSize;
    console.log(start + '-' + end);

    setFilteredData(data.slice(start, end));
  }, [current]);











  return (
    <div class="wrapper">
      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">
          <Breadcrumb title="Current OPD Patient" />

          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card card-primary card-outline">
                    <div class="card-body">
                      <div
                        id="example1_wrapper"
                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                      >

                        <br />
                        <div class="row">
                          <div class="col-sm-12">
                            <table
                              class="table table-striped table-bordered rounded"

                            >
                              <thead>
                                <tr role="row">
                                  <th> Sr.No.</th>
                                  <th>UHID No</th>
                                  <th>Booking Date</th>
                                  <th> Patient Name</th>
                                  <th>Address</th>
                                  <th>Gender</th>
                                  <th> Age</th>
                                  <th class="col-md-2">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredData.map((record, index) =>
                                  <tr key={record.id}>
                                    <td>{index + 1}</td>
                                    <td>{record.uhid}</td>
                                    <td>{record.booking_date}</td>
                                    <td>{record.pname}</td>
                                    <td>{record.paddress}</td>
                                    <td>{record.gender}</td>
                                    <td>{record.age}</td>

                                    <td>
                                      <Button href="#" variant="outline-primary" size="sm">
                                        View Bill
                                      </Button>
                                      {" "}
                                      <Button href="/edit-opd-bill" variant="outline-primary" size="sm">
                                        Edit Bill
                                      </Button>
                                    </td>
                                  </tr>
                                )
                                }





                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row">

                          <div class="col-sm-12 col-md-6">
                            <div
                              class="dataTables_paginate paging_simple_numbers"
                              id="example1_paginate"
                            >
                              <ul class="pagination">
                                <li
                                  class="paginate_button page-item previous disabled"
                                  id="example_previous"
                                >
                                  <Button
                                    class="page-link"
                                    onClick={prevPage}
                                  >
                                    Prev
                                  </Button>
                                </li>
                                <li class="paginate_button page-item active">
                                  <span>
                                    Page {current} to {totalPage}
                                  </span>
                                </li>
                                <li
                                  class="paginate_button page-item next disabled"
                                  id="example_next"
                                >
                                  <Button class="page-link"
                                    onClick={nextPage}
                                  >
                                    Next
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pagination " style={{ float: 'end' }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <Footer />
    </div>
  );
};
export default CurrentOPD;
