

import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { Button } from 'react-bootstrap';

const EditOpdPatientDetail = () => {
  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Edit OPD Patient Details" />

          <div class="col-6 col-lg-6">
            <div class="card border-primary border-bottom border-3 border-0">
              <div class="card-body">

                {/* Form */}
                <form class="row g-3">

                  <div class="col-md-6">
                    <label for="disabledTextInput" class="form-label fw-bold ">Patient Name</label>
                    <input class="form-control" type="text" placeholder="Patient Name" aria-label="Disabled input example" disabled="" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label fw-bold">Phone</label>
                    <input class="form-control" type="text" placeholder="Patient Name" aria-label="Disabled input example" disabled="" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label fw-bold">Address</label>
                    <input class="form-control" type="text" placeholder="Address" aria-label="Disabled input example" disabled="" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label fw-bold">Age</label>
                    <input class="form-control" type="text" placeholder="Age" aria-label="Disabled input example" disabled="" />
                  </div>
                  <div class="col-md-6">
                    <label for="" class="form-label fw-bold">Gender</label>
                    <input class="form-control" type="text" placeholder="Gender" aria-label="Disabled input example" disabled="" />
                  </div>

                  <div class="col-md-12">
                    <input class="btn btn-primary px-3" type="submit" value="Submit" />
                  </div>
                </form>

              </div>
            </div>
          </div>




        </div>
      </div>

      <Footer />
    </div>
  );
}
export default EditOpdPatientDetail;