

import { useEffect, useState } from 'react';
import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const schema = yup.object({
  patientname: yup.string(),
  phone: yup.string(),
  address: yup.string(),
  age: yup.string(),
  gender: yup.string(),
  doctor: yup.string().required("This field is required"),
});

const BookOpd = () => {


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [doctors, setDoctors] = useState([]);

  const [doctorId, setDoctorId] = useState(0);
  const [uhid, setUhid] = useState('');
  const [pname, setPname] = useState('');
  const [data, setData] = useState([]);

  const getPatientDetailsByUHID = async () => {
    // var uhidSearch = document.getElementById("uhid").value
    await fetch("https://hospitalapi.projectupdate.in/api/getPatientDetailsByUHID?uhid=" + uhid)
      .then(res => res.json())
      .then(data => {
        //document.getElementById("pname").value = data.patient_name;
        setPname(data.patient_name);
        document.getElementById("phone").value = data.phone_number;
        document.getElementById("address").value = data.Address;
        document.getElementById("age").value = data.age;
        document.getElementById("gender").value = data.gender;

      })

  }

  const allDoctors = async () => {

    await fetch("https://hospitalapi.projectupdate.in/api/getDoctorDetails")
      .then(res => res.json())
      .then(data => {
        setDoctors(data);
      })
  }

  useEffect(() => {
    allDoctors();
  }, [])


  const visitList = async () => {
    await fetch("https://hospitalapi.projectupdate.in/api/getCurrentPatientDetails")
      .then(res => res.json())
      .then(dt => {
        setData(dt);
      })
  }
  useEffect(() => {
    visitList();

  }, []);



  // submit

  var record = {
    "doctor_id": doctorId,
    "uhid": uhid,

  }
  const updateOpdRecord = async () => {

    await fetch("https://hospitalapi.projectupdate.in/api/saveBookOPD", {
      body: JSON.stringify(record),
      method: "POST",
      mode: "cors"
    })
      .then(res => res.json())
      .then(data => {
        reset({
          "doctor": "",
          "patientname": "",
          "phone": "",
          "address": "",
          "age": "",
          "gender": "",
        });
        visitList();
      })
  }




  const updateDoctorID = (e) => {
    var did = e.target.value;
    setDoctorId(did);

  }

  const updateUhid = (e) => {
    var uhidUpdate = e.target.value;
    setUhid(uhidUpdate);
  }




  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Book OPD Page" />

          <div class="row">
            {/* Card1 */}
            <div class="col-6 col-lg-6">
              <div class="card border-primary border-bottom border-3 border-0">
                <div class="card-body">
                  <div class="row g-3">
                    <div class="col-4 ">
                      <p class="fw-bold mt-1">Enter Patient UHID </p>
                    </div>
                    <div class="col-5">
                      <input type="text" class="form-control" placeholder="Enter Patient UHID" id="uhid" onChange={(e) => updateUhid(e)} value={uhid} />
                    </div>
                    <div class="col-3">
                      <button type="button" class="btn btn-primary " onClick={getPatientDetailsByUHID}>Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Card1 */}
            {/* Card2 */}

            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card radius-10 border-success border-start border-0 border-4">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div>
                          <p class="mb-0">Registered Today</p>
                          <h4 class="my-1 text-success">56</h4>
                        </div>
                        <div class="text-success ms-auto font-35 "><i class="bx bx-clinic"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card radius-10 border-primary border-start border-3 border-0">
                    <div class="card-body ">

                      <div class="d-flex align-items-center">
                        <div>
                          <p class="mb-0">Remaining Patient</p>
                          <h4 class="my-1 text-primary">20</h4>
                        </div>
                        <div class="text-primary ms-auto font-35"><i class="bx bxs-user-rectangle"></i>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Card2 */}

            {/* Card3 */}

            <div class="col-6 col-lg-6">
              <div class="card border-primary border-bottom border-3 border-0">
                <div class="card-body">

                  {/* Form */}
                  <form class="row g-3" onSubmit={handleSubmit(updateOpdRecord)}>

                    <div class="col-md-6">
                      <label for="disabledTextInput" class="form-label fw-bold ">Patient Name</label>
                      <input class="form-control" type="text" placeholder="Patient Name" readOnly id="pname"
                        {...register("patientname")} value={pname} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">Phone</label>
                      <input class="form-control" type="text" placeholder="Phone" readOnly id="phone"
                        {...register("phone")} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">Address</label>
                      <input class="form-control" type="text" placeholder="Address" readOnly id="address"
                        {...register("address")} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">Age</label>
                      <input class="form-control" type="text" placeholder="Age" readOnly id="age"
                        {...register("age")} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">Gender</label>
                      <input class="form-control" type="text" placeholder="Gender" readOnly id="gender"
                        {...register("gender")} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold" >Doctor</label>
                      <select {...register("doctor")} class="form-select" id="doctors" onChange={(e) => updateDoctorID(e)}>
                        <option value="">Select Doctor</option>
                        {doctors.map((record) =>

                          <option value={record.doctor_id}>{record.doctor_name}</option>
                        )}

                      </select>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-primary px-3" >Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>

            {/* Patient list */}

            <div class="card col-lg-6">
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example" class="table table-striped table-bordered" style={{ width: '100%' }}>
                    <thead class="col-12">
                      <tr>
                        <th>Sr. No</th>
                        <th>Patient Name</th>
                        <th>Doctor</th>
                        <th>Token No.</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody class="col-12">
                      {data.map((record, index) =>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{record.patient_name}</td>
                          <td>{record.doctor_name}</td>
                          <td>{record.token_no}</td>
                          <td>{record.phone_number}</td>
                        </tr>
                      )
                      }
                    </tbody>

                  </table>
                </div>
              </div>
            </div>




          </div>




        </div>
      </div>

      <Footer />
    </div >
  );
}
export default BookOpd;

