import "../App.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    patientname: yup.string().required("Name is required"),
    age: yup.number().positive().integer().required("Age is requireds"),
    phone: yup.number().required("Phone is required"),
    address: yup.string(),
    gender: yup.string()
  })


const GenerateUhid = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const saveRecord = async (data) => {
    var rec = {
      "patient_name": data.patientname,
      "phone_number": data.phone,
      "gender": data.gender,
      "age": data.age,
      "Address": data.address
    }
    await fetch("https://hospitalapi.projectupdate.in/api/saveUHID", {
      body: JSON.stringify(rec),
      method: "POST",
      mode: "cors",
    })
      .then(response => response.json())
      .then(dt => {
        reset({
          "patientname": "",
          "phone": "",
          "gender": "",
          "age": "",
          "address": ""
        })
      });
  }
  return (
    <div class="wrapper">
      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">
          <Breadcrumb title="Generate UHID" />

          <div class="row">
            {/*Card*/}
            <div class="col-6 col-lg-6">
              <div class="card border-primary border-bottom border-3 border-0">
                <div class="card-body">
                  {/*Generate UHID - form*/}
                  <form class="row g-3" onSubmit={handleSubmit(saveRecord)}>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">
                        Patient Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        {...register("patientname")}
                      />
                      {errors.patientname?.message}
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">
                        Phone
                      </label>
                      <input type="number" class="form-control"{...register("phone")} />
                      {errors.phone?.message}
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">
                        Address
                      </label>
                      <input type="text" class="form-control" {...register("address")} />
                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">
                        Age
                      </label>
                      <input type="number" class="form-control"{...register("age")} />{errors.age?.message}

                    </div>
                    <div class="col-md-6">
                      <label for="" class="form-label fw-bold">
                        Gender
                      </label>
                      <select
                        {...register("gender")}
                        class="form-select"
                        aria-label=".form-select-sm example"
                      >
                        <option selected="" class="">
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-primary px-3" >Submit</button>
                      {/* <button  "> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default GenerateUhid;
