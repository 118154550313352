const Breadcrumb = ({ title }) => {
    return (
        <>
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">{title}</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><a href="/"><i class="bx bx-home-alt"></i></a>
                            </li>

                        </ol>
                    </nav>
                </div>

            </div>
        </>
    );
}
export default Breadcrumb;