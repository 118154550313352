

import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';

const ReAdmitIPD = () => {
  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Re-Registration IPD" />

          <div class="col-12 col-lg-12">
            <div class="card border-primary border-bottom border-3 border-0">
              <div class="card-body">
                <div class="row g-3">

                  <div class="col-11">
                    <input type="text" class="form-control" placeholder="Enter Patient UHID here" aria-label="Zip" />
                  </div>
                  <div class="col-1">
                    <button type="button" class="btn btn-primary ">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>

      <Footer />
    </div>
  );
}
export default ReAdmitIPD;