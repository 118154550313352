import "../App.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import Widget from "../components/Widget";

function Dashboard() {
  return (
    <div class="wrapper">
      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">
          <Breadcrumb title="Dashboard Page" />

          {/* Dashboard Cards*/}

          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div class="col">
              <Widget title="Total Patients" value="249" icon={<i class='bx bx-bed'></i>} color="success" />
            </div>

            <div class="col">
              <Widget title="Current Admission" value="845" icon={<i class='bx bx-user'></i>} color="primary" />
            </div>

            <div class="col">
              <Widget title="Today Collection" value="495" icon={<i class='bx bx-money'></i>} color="warning" />
            </div>

            <div class="col">
              <Widget title="Total Doctors" value="45" icon={<i class='bx bx-group'></i>} color="danger" />
            </div>

          </div>

          {/*End Dashboard Cards*/}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;
