

import '../App.css';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { Button } from 'react-bootstrap';

const View_OPD_Patient_Detail = () => {
  return (
    <div class="wrapper">

      <Sidebar />

      <div class="page-wrapper">
        <div class="page-content">

          <Breadcrumb title="Patient OPD Details" />

          <div class="row">

            {/* Card1*/}

            <div class="col-6 col-lg-6">
              <div class="card border-primary border-start border-3 border-0">
                <div class="card-body">

                  <div class="row">
                    <div class="col-4 fw-bold">
                      Patient UHID:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 fw-bold">
                      Patient Name:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 fw-bold">
                      Address:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>
                  <div class="row pt-1">
                    <div class="col-4 fw-bold">
                      Phone Number:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 fw-bold">
                      Patient Age:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 fw-bold">
                      Patient Gender:
                    </div>
                    <div class="col-8">
                      <p>Name</p>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            {/* Card1 end */}

            {/* Card2 */}

            <div class="card col-lg-6">
              <div class="card-body">
                <div class="table-responsive">
                  <h5>Patient OPD History</h5>
                  <hr />
                  <table id="example" class="table table-striped table-bordered" style={{ width: "100%" }}>
                    <thead class="col-12">
                      <tr>
                        <th>Sr. No</th>
                        <th>Booking Date</th>
                        <th>Doctor Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody class="col-12">
                      <tr>
                        <td>1</td>
                        <td>23/12/2025</td>
                        <td>Dr. Daaku</td>
                        <td><Button variant="outline-primary" size="sm"><i class="bx bx-printer"></i></Button></td>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </div>

            {/* Card2 end */}
          </div>




        </div>
      </div>

      <Footer />
    </div>
  );
}
export default View_OPD_Patient_Detail;